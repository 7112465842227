import { createContext, useContext, useEffect, useState } from 'react';
import { env } from '../../env.mjs';
import { ShopifySessionService } from '../../Service/ShopifySessionService';
import { PlatformType, usePlatform } from './PlatformProvider';
type Props = {
  children: React.ReactNode;
};

type SessionContextInterface = {
  sessionToken: string;
};

const SessionContext = createContext<SessionContextInterface>({ sessionToken: '' });

export function useSessionToken() {
  return useContext(SessionContext);
}

/**
 * get signed_payload_jwt query param from big commerce initial request
 */
export function getBigCommerceSessionToken(url: string): string {
  const params = new URLSearchParams(url);
  const jwt = params.get('signed_payload_jwt');
  return jwt || '';
}

async function getToken(platform: PlatformType): Promise<string> {
  switch (platform) {
    case 'BigCommerce':
      return getBigCommerceSessionToken(window.location.search);
    case 'Shopify':
      return ShopifySessionService.getSessionToken();
    default:
      return getBigCommerceSessionToken(window.location.search);
  }
}

function getErrorMsg(platform: PlatformType) {
  let msg: string;
  switch (platform) {
    case 'BigCommerce':
      msg = 'please reload the page, so we can verify your login session';
      break;
    case 'Shopify':
      msg = 'please reload the page or visit in Shopify admin dashboard, so we can verify your Shopify session';

      break;
  }

  return msg;
}

/**
 * Set authentication to verify this app is used by webshop platform and prevent CSRF
 * @returns session token, or JWT
 */
export default function SessionProvider({ children }: Props) {
  const platform = usePlatform();
  const [token, setToken] = useState<string>('');
  const [isLoading, setLoading] = useState(false);

  const initSessionToken = async () => {
    setLoading(true);

    const newToken = await getToken(platform);
    setToken(newToken);

    setLoading(false);
  };

  useEffect(() => {
    initSessionToken();
  }, []);

  if (env.DevEnv === 'local') {
    return <SessionContext.Provider value={{ sessionToken: 'jwt' }}>{children}</SessionContext.Provider>;
  }

  if (isLoading) {
    return null;
  }

  if (isLoading || (!token && window.location.pathname !== '/health-check')) {
    return (
      <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {getErrorMsg(platform)}
      </div>
    );
  }

  return <SessionContext.Provider value={{ sessionToken: token }}>{children}</SessionContext.Provider>;
}
