import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { NavigateWithParams } from '../../util';
import { useBrand } from './BrandProvider';

export type Step = {
  name: string;
  completed: boolean;
  href: string;
  enabled: boolean;
};

export type StepInterface = {
  steps: Step[];
  currentStep: Step;
  nextStep(): void;
  goBack(): void;
};

type Props = {
  children?: ReactNode;
  steps: Step[];
};

const StepContext = createContext<StepInterface | undefined>(undefined);

export function StepProvider({ children, steps }: Props) {
  const [currentStep, setCurrentStep] = useState<Step>(steps[0]);
  const navigate = useNavigate();
  const brand = useBrand();

  const NextStep = () => {
    const index = steps.findIndex((step) => {
      return step.href === currentStep.href;
    });

    steps[index].completed = true;
    if (index < steps.length) {
      const currentStep = steps[index + 1];
      steps[index + 1].enabled = true;
      updateCurrentStep(currentStep);
    }
  };

  const GoBack = () => {
    const index = steps.findIndex((step) => {
      return step.href === currentStep.href;
    });

    if (index > 0) {
      const currentStep = steps[index - 1];
      updateCurrentStep(currentStep);
      NavigateWithParams(navigate, currentStep.href, {
        state: {
          position: 3,
        },
      });
    }
  };

  useEffect(() => {
    const path = window.location.pathname;
    const step = steps.find((x) => x.href === path);

    if (
      !brand.completed &&
      (!step || (path !== '/' && currentStep?.href !== path && !step?.enabled)) &&
      window.location.pathname !== '/health-check'
    ) {
      NavigateWithParams(navigate, '/');
      return;
    }

    if (currentStep.href !== path && step) {
      if (path === '/') {
        setCurrentStep(steps[0]);
        return;
      }

      setCurrentStep(step);
    }
  }, [steps, brand.completed, currentStep.href, navigate]);

  const updateCurrentStep = (newStep: Step) => {
    setCurrentStep(newStep);
    if (newStep.href) NavigateWithParams(navigate, newStep.href);
  };

  return (
    <StepContext.Provider
      value={{
        steps: steps,
        currentStep: currentStep,
        nextStep: NextStep,
        goBack: GoBack,
      }}
    >
      {children}
    </StepContext.Provider>
  );
}

export function useStepper() {
  const context = useContext(StepContext);
  if (!context) throw new Error('useStepper must be called inside the a stepprovider');

  return context;
}
