import { createContext, useContext } from 'react';
import { BigCommerceBrandService } from '../../Service/bigCommerceService';
import IBrandService from '../../Service/interfaces/IBrandService';
import { ShopifyBrandService } from '../../Service/shopifyService';
import { PlatformType, usePlatform } from './PlatformProvider';

interface Props {
  children: React.ReactNode;
}

// this interface contains all services used in this app.
// add more service types if required in future
interface ServiceContextInterface {
  brandService: IBrandService;
}

const ServiceContext = createContext<ServiceContextInterface>({
  brandService: new BigCommerceBrandService(),
});

export function useService() {
  return useContext(ServiceContext);
}

/**
    'platform-id' is stored in brands.platforms mysql DB
 * @returns 
 */
const filterPlatform = (platform: PlatformType) => {
  switch (platform) {
    case 'BigCommerce':
      return new BigCommerceBrandService();
    case 'Shopify':
      return new ShopifyBrandService();
    default:
      return new BigCommerceBrandService();
  }
};
/**
 * Provide services based which platform is running the app. E.g., if this app runs on Big Commerce, then big commerce brand service is
 * provided, instead of shopify brand service.
 * @returns the services to run the app
 */
export function ServiceProvider({ children }: Props) {
  const platform = usePlatform();
  const brandService = filterPlatform(platform);

  return (
    <ServiceContext.Provider
      value={{
        brandService: brandService,
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
}
