/* eslint-disable @typescript-eslint/no-unused-vars */
import IBrandService, {
  BrandData,
  GetBrandProps,
  SaveLogoArgs,
  SaveSettingsProps,
  UpdateCheckoutProps,
} from './interfaces/IBrandService';
import { env } from '../env.mjs';
import { ShopifySessionService } from './ShopifySessionService';

export class ShopifyBrandService implements IBrandService {
  async saveSettings({ body }: SaveSettingsProps): Promise<string> {
    const sessionToken = await ShopifySessionService.getSessionToken();

    try {
      type UpdateBrandResponse = {
        url: string;
      };

      const response = await fetch(env.GatewayUrl + '/shopify/admin/brand', {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
          'X-Shopify-Session-Token': `Bearer ${sessionToken}`,
        },
      });
      const jwt: UpdateBrandResponse = await response.json();
      return jwt.url;
    } catch (error) {
      throw new Error('Something went wrong, please try again');
    }
  }
  uploadToObjectStorage({ url, file }: SaveLogoArgs): Promise<string> {
    // https://learn.microsoft.com/en-us/azure/developer/javascript/tutorial/browser-file-upload-azure-storage-blob?tabs=typescript%2Cuser-delegated-sas#:~:text=The%20createBlobInContainer%20function%20uploads%20the%20file%20to%20the%20container%2C%20using%20the%20BlockBlobClient%20class%2C%20uploadData%20method.%20The%20content%20type%20must%20be%20sent%20with%20the%20request%20if%20you%20intend%20to%20use%20browser%20functionality%2C%20which%20depends%20on%20the%20file%20type%2C%20such%20as%20displaying%20a

    return fetch(url, {
      method: 'PUT',
      body: file,
      headers: {
        'x-ms-blob-type': 'BlockBlob',
        'x-ms-blob-content-type': 'image/png',
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Could not upload logo: ${res.status}`);
        }
        return url;
      })
      .catch((e) => {
        throw new Error('Uploading URL is invalid', e);
      });
  }
  formatImageURL(brandId: string | undefined): string | undefined {
    if (!brandId) return undefined;

    //breaks the image cache
    const imageUrl = env.CDNUrl + brandId + '.png?t=' + new Date().getMilliseconds();

    return imageUrl;
  }
  getBrandCheckout(props: GetBrandProps): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  updateCheckout({ jwt, reset }: UpdateCheckoutProps): Promise<Response> {
    throw new Error('Method not implemented.');
  }
  async getBrand(props: GetBrandProps): Promise<BrandData> {
    if (env.DevEnv === 'local') {
      return new Promise<BrandData>((resolve) => {
        resolve({
          id: '1c232cc9-f726-47be-8b26-b84057745517',
          name: 'Sweaters Lovers',
          description: 'Selling premium quality clothes since 2018',
          slogan: 'Keep them warm!',
          email_address: 'sweaters.lovers@gmail.com',
          address: 'Eindhoven, Netherlands',
          completed: true,
        });
      });
    }

    const sessionToken = await ShopifySessionService.getSessionToken();

    return fetch(env.GatewayUrl + '/shopify/admin/brand/get', {
      method: 'GET',
      headers: {
        'X-Shopify-Session-Token': `Bearer ${sessionToken}`,
      },
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`Could not get brand: ${res.status}`);
      }

      return res.json().then((json: BrandData) => {
        return json;
      });
    });
  }
}
