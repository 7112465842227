import { NavigateFunction, NavigateOptions, To } from 'react-router';

export const NavigateWithParams = (
  navigate: NavigateFunction,
  ...args: [to: To, options?: NavigateOptions] | [delta: number]
) => {
  const params = window.location.search;
  if (typeof args[0] === 'number') navigate(args[0]);
  else navigate(args[0] + params, args[1]);
};
