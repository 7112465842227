import { Box, Container, Toolbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import AnyIdLogo from '../../assets/img/logos/any-id-logo.png';
import { NavigateWithParams } from '../../util';
import './TopNavigation.css';

type props = {
  isFullscreen?: boolean;
};

export function TopNav({ isFullscreen = false }: props) {
  const navigate = useNavigate();

  return (
    <>
      {isFullscreen ? (
        <Box
          sx={{
            height: '25%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img height="36px" alt="any-id" src={AnyIdLogo} className="hover-pointer" />
        </Box>
      ) : (
        <Toolbar
          className="toolbar"
          disableGutters
          sx={{
            height: '67px',
          }}
        >
          <Container sx={{ alignItems: 'center', display: 'flex' }}>
            <Box sx={{ flexGrow: 1, display: 'flex' }}>
              <img
                onClick={() => {
                  NavigateWithParams(navigate, '/');
                }}
                height="37px"
                alt="any-id"
                src={AnyIdLogo}
                className="hover-pointer"
              />
            </Box>
          </Container>
        </Toolbar>
      )}
    </>
  );
}
