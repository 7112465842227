import { env } from '../env.mjs';
import IBrandService, {
  BrandData,
  GetBrandProps,
  SaveLogoArgs,
  SaveSettingsProps,
  UpdateCheckoutProps,
} from './interfaces/IBrandService.js';

export class BigCommerceBrandService implements IBrandService {
  async saveSettings({ body, jwt }: SaveSettingsProps): Promise<string> {
    if (env.DevEnv === 'local') {
      return new Promise<string>((resolve) => {
        resolve('');
      });
    }
    try {
      const response = await fetch(env.GatewayUrl + '/bigcommerce/admin/brand?signed_payload_jwt=' + jwt, {
        method: 'PUT',
        body: JSON.stringify(body),
      });

      // TODO: #71 #72 might be wrong as the escaping encoding (either unicode/utf8) is different than browser and fetch API
      const preSignedUrl = await response.text();
      return preSignedUrl;
    } catch (error) {
      throw new Error('Something went wrong, please try again');
    }
  }
  uploadToObjectStorage({ url, file }: SaveLogoArgs): Promise<string> {
    return fetch(url, {
      method: 'PUT',
      body: file,
      headers: {
        'x-ms-blob-type': 'BlockBlob',
        'x-ms-blob-content-type': 'image/png',
      },
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`could not upload logo: ${res.status}`);
      }
      return url;
    });
  }
  formatImageURL(brandId: string | undefined): string | undefined {
    if (!brandId) return undefined;

    //breaks the image cache
    return env.CDNUrl + brandId + '.png?t=' + new Date().getMilliseconds();
  }
  getBrandCheckout(props: GetBrandProps): Promise<boolean> {
    const [, { jwt }] = props.queryKey;

    return fetch(env.GatewayUrl + '/bigcommerce/admin/brand/checkout?signed_payload_jwt=' + jwt).then((res) => {
      if (!res.ok) throw new Error('something went wrong please try again');

      return res.text().then((text) => {
        return text === 'true';
      });
    });
  }
  updateCheckout({ jwt, reset }: UpdateCheckoutProps): Promise<Response> {
    const url = '/bigcommerce/admin/brand/checkout?signed_payload_jwt=' + jwt + `${reset ? '&reset=true' : ''}`;
    return fetch(env.GatewayUrl + url, {
      method: 'PUT',
    });
  }
  getBrand(props: GetBrandProps): Promise<BrandData> {
    const [, { jwt }] = props.queryKey;

    if (env.DevEnv) {
      return new Promise((resolve) => {
        resolve({
          id: 'big commerce id',
          name: 'big commerce  name',
          description: 'big commerce  description',
          slogan: 'big commerce  slogan',
          email_address: 'email',
          address: 'address',
          completed: true,
        } as BrandData);
      });
    }

    return fetch(env.GatewayUrl + '/bigcommerce/admin/brand?signed_payload_jwt=' + jwt, {
      method: 'GET',
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`could not get brand: ${res.status}`);
      }

      return res.json().then((json) => {
        return json as BrandData;
      });
    });
  }
}
