import React, { ReactNode } from 'react';
import { AnyButton } from '../../gp-components/Button/AnyButton';
import { AnyTypography } from '../../gp-components/Typography/AnyTypography';
import FullScreenRootContainer from '../Container/FullScreenRootContainer';
import { TopNav } from '../Navigation/TopNavigation';

type props = {
  children?: ReactNode;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class Error extends React.Component<props, any> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError)
      return (
        <FullScreenRootContainer>
          <TopNav />
          <div
            style={{
              top: '50%',
              marginTop: '-55px',
              position: 'absolute',
              width: '100%',
              textAlign: 'center',
              height: '110px',
            }}
          >
            <AnyTypography sx={{ marginBottom: '10px' }} type="h1">
              {'An error ocurred'}
            </AnyTypography>
            <AnyTypography sx={{ marginBottom: '20px' }} type="subtitle1">
              {'please reload the page'}
            </AnyTypography>
            <AnyButton anytype="green" onClick={() => window.location.reload()}>
              {'reload'}
            </AnyButton>
          </div>
        </FullScreenRootContainer>
      );
    return children;
  }
}

export default Error;
