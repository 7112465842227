import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { theme } from '../../theme';

// helps with the navigation margins

const FullScreenRootContainer = styled(Box)(() => ({
  // mobile
  [theme.breakpoints.down('md')]: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  // desktop
  [theme.breakpoints.up('md')]: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default FullScreenRootContainer;
