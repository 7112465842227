import { z } from 'zod';
/*
  This code safe parses the environment variables so we dont have to constantly check if they are defined or not
  Define your variable in the `envSchema` object using zod to validate the input
  Then deconstruct your env in the `rawEnv` object as shown
*/
const envSchema = z.object({
  GatewayUrl: z.string(),
  CDNUrl: z.string(),
  IframesUrl: z.string(),
  shopifyApiKey: z.string(),
  DevEnv: z.string(),
});

const rawEnv = {
  GatewayUrl: process.env.REACT_APP_GATEWAY_URL,
  CDNUrl: process.env.REACT_APP_CDN_URL,
  IframesUrl: process.env.REACT_APP_IFRAMES_URL,
  shopifyApiKey: process.env.REACT_APP_SHOPIFY_API_KEY,
  DevEnv: process.env.REACT_APP_DEV_MODE,
};

const _env = envSchema.safeParse(rawEnv);

const formatErrors = (errors) =>
  Object.entries(errors)
    .map(([name, value]) => {
      if (value && '_errors' in value) return `${name}: ${value._errors.join(', ')}\n`;
      return '';
    })
    .filter(Boolean);

if (_env.success === false) {
  console.error('❌ Invalid environment variables:\n', ...formatErrors(_env.error.format()));
  throw new Error('Invalid environment variables');
}

export const env = _env.data;
