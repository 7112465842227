import { AppBridgeState, ClientApplication, createApp } from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge-utils';
import { env } from '../env.mjs';

let app: ClientApplication<AppBridgeState>;

function init() {
  let host: string;

  if (env.DevEnv === 'local') host = 'YWRtaW4uc2hvcGlmeS5jb20vc3RvcmUvYW55aWQtZGV2';
  else {
    const urlParams = new URLSearchParams(window.location.search);
    const hostParam = urlParams.get('host');

    host = hostParam ?? '';
  }

  app = createApp({
    apiKey: env.shopifyApiKey,
    // The host of the specific shop that's embedding your app. This value is provided by Shopify as
    // a URL query parameter that's appended to your application URL when your app is loaded inside the Shopify admin.
    // e.g. YWRtaW4uc2hvcGlmeS5jb20vc3RvcmUvYW55aWQtZGV2 which is admin.shopify.com/store/anyid-dev in base64
    host: host,
    forceRedirect: true,
  });
}

export const ShopifySessionService = {
  /**
   * get signed_payload_jwt query param from big commerce initial request
   */
  getSessionToken: async (): Promise<string> => {
    if (env.DevEnv === 'local') {
      return 'dev mode local';
    }
    try {
      if (!app) {
        init();
      }

      const token = await getSessionToken(app);
      return token;
    } catch (e) {
      throw new Error('Getting Shopify session token works only in production mode, inside embedded iframe');
    }
  },
};
