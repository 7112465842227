import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { theme } from '../../theme';

type Props = {
  type?: keyof typeof AnyTypographyTypes;
};

const fonts = {
  roboto: `"Roboto", sans-serif`,
  poppins: `"Poppins", sans-serif`,
};

export const AnyTypographyTypes = {
  body1: {
    general: {
      fontFamily: fonts.roboto,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
    },
    mobile: {
      fontSize: '16px',
    },
    desktop: {
      fontSize: '14px',
    },
  },
  body2: {
    general: {
      fontFamily: fonts.roboto,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
    },
    mobile: {
      fontSize: '14px',
    },
    desktop: {
      fontSize: '12px',
    },
  },
  h1: {
    general: {
      fontFamily: fonts.poppins,
      fontStyle: 'normal',
      fontWeight: 600,

      lineHeight: '24px',
    },
    mobile: {
      fontSize: '20px',
    },
    desktop: {
      fontSize: '24px',
    },
  },
  h2: {
    general: {
      fontFamily: fonts.poppins,
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '24px',
      fontSize: '18px',
    },
  },
  h3: {
    general: {
      fontFamily: fonts.poppins,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
    },
  },
  buttonText: {
    general: {
      fontFamily: fonts.poppins,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '10px',
    },
  },
  jumbo: {
    general: {
      fontFamily: fonts.poppins,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '40px',
      lineHeight: '40px',
    },
  },
  subtitle1: {
    general: {
      fontFamily: fonts.poppins,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  button1: {
    general: {
      fontFamily: fonts.poppins,
      fontWeight: 600,
    },
    mobile: {
      fontSize: '16px',
    },
    desktop: {
      fontSize: '14px',
    },
  },
};

export const AnyTypography = styled(Typography)<Props>(({ type = 'body1' }) => {
  return {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(AnyTypographyTypes as any)[type].general,
    [theme.breakpoints.down('md')]: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(AnyTypographyTypes as any)[type].mobile,
    },
    [theme.breakpoints.up('md')]: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(AnyTypographyTypes as any)[type].desktop,
    },
  };
});
