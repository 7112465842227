import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

/**
 * Load /public folder json files. The default namespace is "translation"
 */
i18n
  .use(initReactI18next)
  // load in /public folder json files
  .use(Backend)
  .use(LanguageDetector)
  .init({
    detection: {
      order: ['querystring'],
      lookupQuerystring: 'locale',
    },

    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    initImmediate: false,
  });

export default i18n;
