import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { anyPalette } from '../../theme';

type Props = {
  anytype?: keyof typeof types;
};

export function AnyButtonTypes(type: string) {
  return {
    ...defaultStyling,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(types as any)[type],
  };
}

const types = {
  blue: {
    backgroundColor: anyPalette.primary,
    '&:hover': {
      opacity: '0.8',
      boxShadow: 'none',
      backgroundColor: anyPalette.primary,
    },
  },
  green: {
    backgroundColor: anyPalette.secondary,
    '&:hover': {
      opacity: '0.8',
      boxShadow: 'none',
      backgroundColor: anyPalette.secondary,
    },
  },
  orange: {
    backgroundColor: anyPalette.orange,
    '&:hover': {
      opacity: '0.8',
      boxShadow: 'none',
      backgroundColor: anyPalette.orange,
    },
  },
  white: {
    backgroundColor: 'white',
    color: 'black',
    '&:hover': {
      opacity: '0.8',
      boxShadow: 'none',
      backgroundColor: 'white',
    },
    border: '1px solid ' + anyPalette.sandDark,
  },
  transparent: {
    color: 'black',
    fontSize: '14px',
    '&:hover': {
      opacity: '0.8',
      boxShadow: 'none',
      backgroundColor: 'white',
    },
  },
  grey: {
    backgroundColor: '#dddbd7',
    color: 'black',
    '&:hover': {
      opacity: '0.8',
      boxShadow: 'none',
      backgroundColor: '#dddbd7',
    },
  },
  red: {
    backgroundColor: anyPalette.lightRed,
    '&:hover': {
      opacity: '0.8',
      backgroundColor: anyPalette.lightRed,
    },
  },
};

const defaultStyling = {
  fontFamily: 'Poppins',
  fontWeight: '600',
  fontSize: '12px',
  lineHeight: '24px',
  width: 'fit-content',
  textTransform: 'none',
  color: 'white',
  boxShadow: 'none',
  padding: '5px 10px',
  '&:hover': {
    opacity: '0.8',
    boxShadow: 'none',
  },
  '&:disabled': {
    backgroundColor: anyPalette.sandMedium,
  },
  borderRadius: '4px',
};

export const AnyButton = styled(Button)<Props>(({ anytype = 'blue' }) => AnyButtonTypes(anytype));
