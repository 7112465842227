import { useQuery } from '@tanstack/react-query';
import { createContext, useContext } from 'react';
import { Brand } from '../../models/brand';
import { useSessionToken } from './SessionProviderMiddleware';
import { useService } from './ServiceProvider';

interface Props {
  children: React.ReactNode;
}

const BrandContext = createContext<Brand>({
  loading: true,
  id: '',
  name: '',
  description: '',
  slogan: '',
  email: '',
  address: '',
  completed: false,
});

export function useBrand() {
  return useContext(BrandContext);
}

export default function BrandProvider({ children }: Props) {
  const JwtContext = useSessionToken();
  const service = useService();

  const { isLoading, isError, data, error } = useQuery(
    ['Get-brand', { jwt: JwtContext.sessionToken }],
    service.brandService.getBrand
  );

  if (isError && window.location.pathname !== '/health-check') {
    throw error;
  }

  return (
    <BrandContext.Provider
      value={{
        id: data?.id ?? '',
        name: data?.name ?? '',
        description: data?.description ?? '',
        slogan: data?.slogan ?? '',
        email: data?.email_address ?? '',
        address: data?.address ?? '',
        completed: data?.completed ?? false,
        loading: isLoading,
      }}
    >
      {children}
    </BrandContext.Provider>
  );
}
