import { Suspense, lazy } from 'react';

import { Toaster } from 'react-hot-toast';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import BrandProvider, { useBrand } from './Components/Providers/BrandProvider';
import SessionProvider from './Components/Providers/SessionProviderMiddleware';

import { ThemeProvider } from '@mui/system';
import { theme } from './theme';
import { Step, StepProvider } from './Components/Providers/StepProvider';
import { Error } from './Components/Error/Error';
import { ServiceProvider } from './Components/Providers/ServiceProvider';
import PlatformProvider from './Components/Providers/PlatformProvider';
import { BottomNav } from './Components/Navigation/BottomNavigation';

function App() {
  const brand = useBrand();

  const steps: Step[] = [
    {
      name: 'Home',
      completed: true,
      href: '/',
      enabled: true,
    },
    {
      name: 'Installation',
      completed: brand.completed,
      href: '/setup',
      enabled: false,
    },
    {
      name: 'Webshop',
      completed: brand.completed,
      href: '/settings',
      enabled: false,
    },
    {
      name: 'Completed',
      completed: true,
      href: '/completed',
      enabled: true,
    },
  ];

  // lazy load pages
  const HomePage = lazy(() => import('./Pages/Home'));
  const Setup = lazy(() => import('./Pages/Setup'));
  const SettingsPage = lazy(() => import('./Pages/Settings'));
  const CompletedPage = lazy(() => import('./Pages/Completed/Completed'));

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Error>
          <PlatformProvider>
            <SessionProvider>
              <ServiceProvider>
                <BrandProvider>
                  <Toaster position="bottom-left" />
                  <StepProvider steps={brand.completed ? [] : steps}>
                    <Suspense fallback={<div></div>}>
                      <Routes>
                        <Route path="/setup" element={<Setup />} />
                        <Route path="/settings" element={<SettingsPage />} />
                        <Route path="/completed" element={<CompletedPage />} />
                        <Route path="/health-check" element={''} />
                        <Route path="/" element={brand.completed ? <SettingsPage /> : <HomePage />} />
                      </Routes>
                    </Suspense>
                  </StepProvider>
                  <BottomNav />
                </BrandProvider>
              </ServiceProvider>
            </SessionProvider>
          </PlatformProvider>
        </Error>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
