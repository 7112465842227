import { createContext, useContext } from 'react';

interface Props {
  children: React.ReactNode;
}

export type PlatformType = 'BigCommerce' | 'Shopify';

const PlatformContext = createContext<PlatformType>('BigCommerce');

export function usePlatform() {
  return useContext(PlatformContext);
}

/**
    'platform-id' is stored in brands.platforms mysql DB
 * @returns 
 */
const filterPlatform = (platform: string | null): PlatformType => {
  if (!platform) {
    return 'BigCommerce';
  }

  switch (platform) {
    case '9bfe31e7-c004-45b4-81c8-465b4ba10577':
      return 'BigCommerce';
    case '84e5dded-9b2a-11ed-8536-6a59470f0994':
      return 'Shopify';
    default:
      return 'BigCommerce';
  }
};
/**
 * Provide services based which platform is running the app. E.g., if this app runs on Big Commerce, then big commerce brand service is
 * provided, instead of shopify brand service.
 * @returns the services to run the app
 */
export default function PlatformProvider({ children }: Props) {
  const urlParams = new URLSearchParams(window.location.search);
  // 'platform-id' is stored in brands.platforms mysql DB
  const platform = filterPlatform(urlParams.get('platform_id'));

  return <PlatformContext.Provider value={platform}>{children}</PlatformContext.Provider>;
}
