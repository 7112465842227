import { faDownload, faStore } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, CardActionArea, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { anyPalette } from '../../theme';
import { NavigateWithParams } from '../../util';
import { useBrand } from '../Providers/BrandProvider';
import './BottomNavigation.css';

export function BottomNav() {
  const navigate = useNavigate();
  const brand = useBrand();
  const { t } = useTranslation();

  if (!brand.completed && window.location.pathname.includes('/')) return <></>;

  const navigationItems = [
    {
      paths: ['/setup'],
      icon: <FontAwesomeIcon icon={faDownload} />,
      // title: t('bottomNav.home'),
      title: t('My Installation'),
    },
    {
      paths: ['/settings', '/'],
      icon: <FontAwesomeIcon icon={faStore} />,
      // title: t('bottomNav.history'),
      title: t('My Webshop'),
    },
  ];

  function currentPath(paths: string[]) {
    const parentPath = window.location.pathname.split('/').slice(0, 2).join('/');
    return paths.includes(parentPath);
  }

  function dynamicNavigate(href: string) {
    NavigateWithParams(navigate, href);
  }

  return (
    <Box
      className="footer"
      sx={{
        display: { xs: 'flex', md: 'none' },
        bgcolor: 'white',
      }}
    >
      {navigationItems.map((item) => {
        return (
          <CardActionArea className="navigationItem" key={item.title} onClick={() => dynamicNavigate(item.paths[0])}>
            <div className={currentPath(item.paths) ? 'green' : ''}>{item.icon}</div>
            <Typography
              className="navigationText"
              sx={{
                color: currentPath(item.paths) ? anyPalette.green : '',
              }}
            >
              {item.title}
            </Typography>
          </CardActionArea>
        );
      })}
    </Box>
  );
}
